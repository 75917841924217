import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from 'react-intl';

const Awards = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { glob: "**/content/awards/**/*.en.md" } }
          sort: { order: ASC, fields: [frontmatter___order] }
        ) {
          edges {
            node {
              frontmatter {
                title
                lead
                href
                author
                image {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="awardList">
        <div className="container-fluid">
          <div className="row">
            {data.allMarkdownRemark.edges.map((post) => (
              <div className="col-6 col-sm-3 col-md-3 col-lg-2 col-xl-2 feature-item text-center" key={post.id}>
                <a target="_blank" href={post.node.frontmatter.href}>
                  <div className="col-4 offset-4 mb-3">
                    <Img
                      fluid={post.node.frontmatter.image.childImageSharp.fluid}
                      alt="Furlan Snowboards"
                      alt={post.node.frontmatter.title}
                    />
                  </div>
                  <p className="lead mb-0">{post.node.frontmatter.lead}</p>
                  <small><strong>{post.node.frontmatter.author}</strong></small>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
);

export default Awards;
