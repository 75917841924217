import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from 'react-intl';

const Features = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { glob: "**/content/features/*.en.md" } }
          sort: { order: ASC, fields: [frontmatter___order] }
        ) {
          edges {
            node {
              frontmatter {
                title
                lead
                icon
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="featuresList">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 text-center mb-5">
              <h2>Premium quality - Garantied 2 years</h2>
              <p>
                All our boards are made with the best materials available.
              </p>
            </div>
          </div>
          <div className="row">
            {data.allMarkdownRemark.edges.map((post) => (
              <div
                className="col-sm-6 col-xl-3 mb-3 text-center mt-3"
                key={post.node.frontmatter.title}
              >
                <div className="feature-item">
                  <i className={post.node.frontmatter.icon}></i>
                  <h3 className="mb-4">{post.node.frontmatter.title}</h3>
                  <p className="text-muted">{post.node.frontmatter.lead}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
);

export default Features;
