import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from 'react-intl';

const Tech = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { glob: "**/content/tech/**/*.en.md" } }
          sort: { order: ASC, fields: [frontmatter___order] }
        ) {
          edges {
            node {
              html
              id
              frontmatter {
                title
                image1 {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="featuresList">
        <div className="container">
          <div className="row">
            {data.allMarkdownRemark.edges.map((post) => (
              <div className="col-sm-12 col-md-6 col-xl-4 feature-item px-3 py-2" key={post.id}>
                <div className="tech-item">
                  <div className="col-11">
                    <div className="row">
                      <h3 className="col-9">
                        {post.node.frontmatter.title}
                      </h3>
                    </div>
                    <p className="lead mt-2">
                      <div className="image">
                        <Img
                          fluid={post.node.frontmatter.image1.childImageSharp.fluid}
                          alt="Furlan Snowboards"
                        />
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: post.node.html }} />
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
);

export default Tech;
